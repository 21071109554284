<template>
  <el-container>
    <Header pageName="营销组件"></Header>
    <el-main>
      <el-tabs v-model="activeName">
        <el-tab-pane label="营销组件" name="1">
          <el-button type="primary" size="small" @click="add">添加组件</el-button>
          <el-form class="el-form-search" label-width="90px">
            <el-form-item label="组件名称：">
              <el-input v-model="name" placeholder="请输入组件名称" size="small"></el-input>
            </el-form-item>
            <el-form-item label="组件分类：">
              <el-select v-model="classification_id" size="small" placeholder="请选择">
                <el-option v-for="(item, index) in type" :key="index" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button type="primary" size="small" @click="search">搜索</el-button>
              <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="name" label="组件名称" align="center"></el-table-column>
            <el-table-column prop="plugin_desc" label="组件介绍" align="center"></el-table-column>
            <el-table-column prop="classification_name" label="所属分类" align="center"></el-table-column>
            <el-table-column prop="title" label="组件配图" align="center">
              <template v-slot="{ row }">
                <el-image style="max-width: 60px; max-height: 60px" :src="row.image"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="wxroute" label="跳转链接" align="center"></el-table-column>
            <el-table-column prop="type" label="状态" align="center">
              <template v-slot="{ row }">{{ row.type ? '已上线' : '开发中' }}</template>
            </el-table-column>
            <el-table-column prop="is_recommend" label="是否推荐" align="center">
              <template v-slot="{ row }">
                <el-switch v-model="row.is_recommend" :active-value="1" :inactive-value="0"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <el-button @click="edit(row)" size="small" type="text">编辑</el-button>
                <el-button @click="del(row)" size="small" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
          <el-dialog title="添加/编辑组件" :visible.sync="is_showAdd" width="600px">
            <el-form ref="form" :model="addForm" :rules="rules" label-width="110px">
              <el-form-item label="组件名称：" prop="name">
                <el-input v-model="addForm.name"></el-input>
              </el-form-item>
              <el-form-item label="组件介绍：" prop="plugin_desc">
                <el-input v-model="addForm.plugin_desc"></el-input>
              </el-form-item>
              <el-form-item label="所属分类：" prop="classification_id">
                <el-select v-model="addForm.classification_id" placeholder="请选择">
                  <el-option v-for="(item, index) in type" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="跳转链接：" prop="wxroute">
                <el-input v-model="addForm.wxroute"></el-input>
              </el-form-item>
              <el-form-item label="组件配图：" prop="image">
                <add-img :path="addForm.image" :url="1" @getImg="val => (addForm.image = val)"></add-img>
              </el-form-item>
              <el-form-item label="开发状态：" prop="content">
                <el-radio-group v-model="addForm.type">
                  <el-radio :label="1">已上线</el-radio>
                  <el-radio :label="0">开发中</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否推荐：" prop="content">
                <el-switch v-model="addForm.is_recommend" :active-value="1" :inactive-value="0"></el-switch>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="is_showAdd = !1">取 消</el-button>
              <el-button type="primary" @click="addComponent">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="营销分类" name="2">
          <el-button type="primary" size="small" @click="addType">添加分类</el-button>
          <el-form class="el-form-search" label-width="90px">
            <el-form-item label="组件名称：">
              <el-input v-model="typeName" placeholder="请输入分类名称" size="small"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button type="primary" size="small" @click="getType">搜索</el-button>
              <el-button plain size="small" @click="cancleSearchType">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="type" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="displayorder" label="组内权重" align="center"></el-table-column>
            <el-table-column prop="name" label="分类名称" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <el-button @click="editType(row)" size="small" type="text">编辑</el-button>
                <el-button @click="delType(row)" size="small" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title="添加/编辑分类" :visible.sync="is_showAddType" width="600px">
            <el-form ref="form" :model="addTypeForm" :rules="rules1" label-width="100px">
              <el-form-item label="组内权重：">
                <el-input v-model="addTypeForm.displayorder"></el-input>
              </el-form-item>
              <el-form-item label="分类名称：" prop="name">
                <el-input v-model="addTypeForm.name"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="is_showAddType = !1">取 消</el-button>
              <el-button type="primary" @click="addComponentType">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import Header from './components/header';
import Paging from '@/components/paging';
import addImg from '@/components/addImg';
export default {
  components: {
    Header,
    Paging,
    addImg,
  },
  data() {
    return {
      activeName: '1',
      name: '',
      typeName: '',
      classification_id: '',
      total_number: 0,
      page: 1,
      rows: 10,
      list: [],
      type: [],
      is_showAdd: !1,
      is_showAddType: !1,
      addForm: {
        name: '',
        image: '',
        type: 1,
        is_recommend: 0,
        plugin_desc: '',
        wxroute: '',
        classification_id: '',
      },
      addTypeForm: {
        displayorder: '',
        name: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入组件名称',
            trigger: 'blur',
          },
        ],
        image: [
          {
            required: true,
            message: '请添加组件配图',
            trigger: 'blur',
          },
        ],
        plugin_desc: [
          {
            required: true,
            message: '请输入组件介绍',
            trigger: 'blur',
          },
        ],
        wxroute: [
          {
            required: true,
            message: '请输入跳转链接',
            trigger: 'blur',
          },
        ],
        classification_id: [
          {
            required: true,
            message: '请选择所属分类',
            trigger: 'blur',
          },
        ],
      },
      rules1: {
        name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.activeName = this.$route.query.type;
    this.getList();
    this.getType();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    getList() {
      this.$axios
        .post(this.$api.admin.salesPlugin, {
          page: this.page,
          rows: this.rows,
          name: this.name,
          type:2,
          classification_id:this.classification_id
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getType() {
      this.$axios
        .post(this.$api.admin.pluginClass, {
          name: this.typeName,
        })
        .then(res => {
          if (res.code == 0) {
            this.type = res.result.list;
            this.type = this.type.filter(item => item.id > 0);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    cancelSearch() {
      this.page = 1;
      this.rows = 10;
      this.name = '';
      this.classification_id = '';
      this.getList();
    },
    add() {
      this.addForm = {
        name: '',
        image: '',
        type: 1,
        is_recommend: 0,
        plugin_desc: '',
        wxroute: '',
        classification_id: '',
      };
      this.is_showAdd = !0;
    },
    edit(row) {
      this.addForm.name = row.name;
      this.addForm.image = row.image;
      this.addForm.type = row.type;
      this.addForm.is_recommend = row.is_recommend;
      this.addForm.plugin_desc = row.plugin_desc;
      this.addForm.wxroute = row.wxroute;
      this.addForm.classification_id = row.classification_id;
      this.addForm.id = row.id;
      this.is_showAdd = !0;
    },
    del(row) {
      this.$confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.admin.delPlugin, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    addComponent() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.addForm.id ? this.$api.admin.editPlugin : this.$api.admin.addPlugin;
          this.$axios.post(url, this.addForm).then(res => {
            if (res.code == 0) {
              this.is_showAdd = !1;
              this.getList();
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    addType() {
      this.addTypeForm.name = '';
      this.addTypeForm.displayorder = '';
      this.is_showAddType = !0;
    },
    editType(row) {
      this.addTypeForm.name = row.name;
      this.addTypeForm.displayorder = row.displayorder;
      this.addTypeForm.id = row.id;
      this.is_showAddType = !0;
    },
    delType(row) {
      this.$confirm('请确认是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.admin.delPluginClass, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getType();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    cancleSearchType() {
      this.typeName = '';
      this.getType();
    },
    addComponentType() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.addTypeForm.id ? this.$api.admin.editPluginClass : this.$api.admin.addPluginClass;
          this.$axios.post(url, this.addTypeForm).then(res => {
            if (res.code == 0) {
              this.is_showAddType = !1;
              this.getType();
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
  }
}
</style>